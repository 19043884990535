<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition">
          <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title">사용년월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "yearMonth"
                    :format="DATE_FORMAT_YYYY_MM"
                    :notClear="true"
                    depth="Year"
                    v-model="searchFilterOptions.yearMonth"
                    type="lookup-condition"
                    @change="onYearMonthChange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="searchFilterOptions.usedFlag"
                      />
                      <i></i>
                      <div class="label">품목건수 0건 제외</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET"  :ignore="isPopupOpened" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article detail-view-opened" >
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField.title }}</div>
                <div class="header-caption">[{{ gridField.count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">

                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind="initGrid"
                    @actionComplete="gridComplete"
                    @recordClick="onGridClick"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField2.title }}</div>
                <div class="header-caption">{{detailData.useDate}}</div>
                <div class="header-caption">[{{ gridField2.count }}건]</div>
                <ul class="lookup-lookup">
                  <erp-button button-div="GET"  :is-key-color="false" :is-shortcut-button="false" :ignore="isPopupOpened" @click.native="onClickShowStock()">현재고 보기</erp-button>
                </ul>
              </div>
              <div class="header-right">
                <ul class="header-button">

                  <li class="save keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-key-color="true"
                        :is-shortcut-button="true"
                        :ignore="isPopupOpened"
                         @click.native="onClickSave"
                    >
                      저장
                    </erp-button>
                  </li>
                  <li class="add">
                    <erp-button button-div="SAVE" @click.native="onClickAddRow">행추가</erp-button>
                  </li>
                  <li class="delete">
                    <erp-button button-div="DELETE" @click.native="onClickDelRow">행삭제</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid table-side">
                <ejs-grid-wrapper ref="grid2" v-bind="initGrid2"
                                  @headerCellInfo = "onHeaderCellInfo2"
                                  @queryCellInfo="onQueryCellInfo2"
                                  @cellEdit="onCellEdit2"
                                  @recordClick="onGridClick2"
                                  @actionComplete="gridComplete2"/>
              </div>
            </div>
          </section>
        </div>
      </article>

    </div>
    <!-- 각종팝업 -->
    <eqp-oil-search-popup
        ref="eqpOilSearchPopup"
        v-if="isShowEqpOilPopup"
        @popupConfirmed="onPopupConfirmed"
    />
    <supplies-stock-oil-popup
        ref="suppliesStockOilPopup"
        v-if="isShowSuppliesOilStockPopup"
        @popupClosed="onPopupCloseStock"
    />
  </div>
</template>
<script>


import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {
  DATE_FORMAT_YYYY_MM, DATE_FORMAT_YYYY_MM_DD,
  getFormattedDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import { validateFormRefs} from "@/utils/formUtil";
import InputDate from "@/components/common/datetime/InputDate.vue";
import StockEquipmentMng from "@/api/v2/StockManage/StockEquipmentMng";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import EqpOilSearchPopup from "@/views/stock-management/common/EquipmentOilSearchPopup.vue";
import SuppliesStockOilPopup from "@/views/stock-management/common/SuppliesStockOilPopup.vue";



export default {
  name: "OilUseRegistration",
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {SuppliesStockOilPopup, EqpOilSearchPopup, InputDate,  ejsGridWrapper, ErpButton, },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //new edit start
      // isOpen: false,
      isShowEqpOilPopup:false,
      isShowSuppliesOilStockPopup :false,
      DATE_FORMAT_YYYY_MM,
      searchFilterOptions: {
        yearMonth: null,
        usedFlag: false,
      },
      //드롭다운 코드
      commonCodeOptions: {
        //공통코드 들을 받아오는 필드
        wrhousData:[],
        oilSupplies:[],
        oilSuppliesFields: {text: "suppliesName", value: "suppliesCode"},
      },

      gridField: {
        title: "유류 일사용 목록",
        count: 0,
        dataSource: [],
      },
      //detail
      detailData: {
        useDate: null,
        dtlListD:[],
        dtlListU:[],
        dtlListI:[],
      },
      //validate 체크 항목
      gridField2: {
        title: "유류 사용 장비",
        count: 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return this.isShowEqpOilPopup || this.isShowSuppliesOilStockPopup;
    },

    initGrid() {
      return {
        columns: [
          {field: 'useDate',    textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '일자', isPrimaryKey: true},
          {field: 'insertName', textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '등록자',},
          {field: 'cnt',        textAlign: 'right',  width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '품목건수',
            isNumericType: true,  },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
      };
    },
    initGrid2() {
      return {
        columns: [
          {field: 'eqpCode',      textAlign: 'center', width: 120, visible: false,  allowEditing : false, type: 'string', headerText: '장비코드', isPrimaryKey: true},
          {field: 'eqpName',      textAlign: 'center', width: 200, visible: true,   allowEditing : false, type: 'string', headerText: '장비명', },
          {field: 'wrhousCode',   textAlign: 'center', width: 120, visible: true,   allowEditing : true,  type: 'string', headerText: '출고창고',
            editType: "dropdownedit",isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          {field: 'fuelCode',     textAlign: 'center', width: 100,  visible: true,   allowEditing : true, type: 'string', headerText: '사용연료',
            editType: "dropdownedit", foreignKeyField: "comCode", foreignKeyValue: "comName", dataSource: this.commonCodeOptions.oilSupplies,},
          {field: 'qty',          textAlign: 'right',  width: 100,  visible: true,   allowEditing : true, type: 'number', headerText: '사용량' ,
            isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },
          }, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅
          {field: 'userName',     textAlign: 'center', width: 100,  visible: true,   allowEditing : true, type: 'string', headerText: '사용자',},
          {field: 'remarks',      textAlign: 'left',   width: 250, visible: true,   allowEditing : true, type: 'string', headerText: '비고',}
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        //그리드 정합성 체크
        validationRules: {
          wrhousCode:    {required: true},
          qty:    {required: true, min:0.1},
          fuelCode : {
            required: true,
            custom: {
              method: (record) => {
                if(this.$refs.grid2.getBatchCurrentViewRecords().filter(v => v.eqpCode === record.eqpCode && v.fuelCode === record.fuelCode).length>1) {
                  return;
                }
                return true;
              },
              message: "중복된 장비,연료가 있습니다.",
            },
          }
        },
      };

    },
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    validateFormRefs,

    //region******************************* API Call *******************************
    async initData() {
      //금일 설정
      this.detailData.useDate = moment().format('YYYY-MM-DD');
      // 사용월 기본 값...
      this.searchFilterOptions.yearMonth = moment().format(DATE_FORMAT_YYYY_MM);
      // 필터 날짜 조건 정의
      const datePickerInstance = this.$refs.yearMonth.$refs.datepicker.ej2Instances;
      datePickerInstance.max = getFormattedDate(new Date());
      //사용연료
      this.commonCodeOptions.oilSupplies  = await StockCommon.getOilSupplies({useFlag:'Y'});//{useFlag:'Y'}
      //입고창고
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'Y',suppliesFlag:'Y', oilFlag:'Y'});
    },

    async apiGetList() {
      const args = {
        useDateFrom: moment(getFormattedDate(this.searchFilterOptions.yearMonth.concat("-01"))).format(DATE_FORMAT_YYYY_MM_DD),
        useDateTo: moment(getFormattedDate(this.searchFilterOptions.yearMonth.concat("-01"))).endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
        existFlag: this.searchFilterOptions.usedFlag?"Y":"N",
      };
      let today = moment().format('YYYY-MM-DD');
      if(args.useDateTo >= today){
        args.useDateTo = today;
      }
      this.gridField.dataSource = await StockEquipmentMng.getOilUseDayList(args);

      //상세 자동 조회
      setTimeout(() => {
        //기 선택된 행이 있다면 찾아서 자동 선택
        let index = this.$refs.grid.getRowIndexByPrimaryKey(this.detailData.useDate);
        if(index > 0){
          this.$refs.grid.selectRow(index);
        }
        //선택된 행으로 자동 조회
        this.clearDetailData();
        this.detailData.useDate = this.$refs.grid.getSelectedRecords()[0]?.useDate;
        this.apiGetDetailItem({useDate:this.detailData.useDate});
      }, 50);

    },
    async apiGetDetailItem(args) {
      this.gridField2.dataSource = await StockEquipmentMng.getOilUseList(args);
    },
    async apiSave() {
         await StockEquipmentMng.saveOilUseList(this.detailData);
    },
    //endregion

    //region******************************* 버튼 및 컨트롤 이벤트 *******************************
    //사용월 변경시
    onYearMonthChange() {
      this.apiGetList();
    },
    onClickSearch(){
      this.apiGetList();
    },
    onClickAddRow(){
      this.isShowEqpOilPopup = true;
    },
    onClickDelRow(){
      this.$refs["grid2"].deleteRecord();
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    onClickExcel(){
      this.$refs.grid2.excelExport({fileName: this.gridField2.title+".xlsx"});
    },
    async onClickSave() {
      //1.품목 건수 체크
      if(this.gridField2.count === 0){
        this.errorToast("유류 사용 장비를 1건 이상 등록하세요.");
        return;
      }
      //2. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;

      await this.apiSave();

      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      this.onClickSearch();
      await this.apiGetDetailItem({useDate:this.detailData.useDate});

    },
    onClickShowStock(){
      this.isShowSuppliesOilStockPopup = true;
    },


    //endregion
    //region ******************************* 공통 function *******************************

    clearDetailData() {
      this.detailData = {
        useDate: null,
        dtlListD: [],
        dtlListU: [],
        dtlListI: [],
      };
      this.gridField2.dataSource = [];
    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    onGridClick(args){
      this.clearDetailData();
      const {rowData} = args;
      this.detailData.useDate = rowData.useDate;
      this.apiGetDetailItem({useDate:rowData.useDate});
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['treeName',].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 스타일 적용
    onQueryCellInfo2(args) {
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['treeName',].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    // 추가ROW만 수정 가능하게 하기 위해 onCellEdit2,onGridClick2
    onCellEdit2(args){
      this.$refs.grid2.getColumnObject("wrhousCode").allowEditing = args.rowData.dtlId == null;
      this.$refs.grid2.getColumnObject("fuelCode").allowEditing = args.rowData.dtlId == null;
    },
    onGridClick2(args) {
      this.$refs.grid2.getColumnObject("wrhousCode").allowEditing = args.rowData.dtlId == null;
      this.$refs.grid2.getColumnObject("fuelCode").allowEditing = args.rowData.dtlId == null;
    },
    //endregion
    //region******************************* 팝업 이벤트 *******************************
    onPopupConfirmed(args){
      args && args.forEach((record)=>{
        if(this.$refs.grid2.getBatchCurrentViewRecords().findIndex(v => v.eqpCode === record.eqpCode && v.fuelCode === record.fuelCode)>=0) {
          this.errorToast("중복된 장비,연료가 존재합니다. 저장전 연료를 변경하시기 바랍니다.");
          //this.infoToast("중복된 장비,연료는 추가 되지 않습니다.");
          //return;
        }
        this.$refs.grid2.addRecord({
          useDate      : this.detailData.useDate,
          eqpCode      : record.eqpCode,
          eqpName      : record.eqpName,
          wrhousCode   : this.commonCodeOptions.wrhousData[0]?.comCode,
          fuelCode     : record.fuelCode,
          qty          : 1 , //record.qty,
          oldQty       : 0 , //record.qty,
          userName     : this.username,
          remarks      : ""

        });
      });

      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
      this.isShowEqpOilPopup = false;
    },
    onPopupCloseStock(args){
      this.isShowSuppliesOilStockPopup = false;
    }
    //endregion
  },

};

</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-70% - 0px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(30% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(70% - 0px); margin:0}
body .body-footer .section-header {
  display: block;
  position: relative;
  margin: 0px 0px;
}
.voc-section {
  width: 100% !important;
}

</style>