<template>
  <div>
    <ejs-dialog
        ref="dialog"
        header="유류 재고 현황"
        width="900"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :close="onClickClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-section" style="height: auto;">
                  <div class="section-body" style="border: none;">
                    <div class="article-right" style="width: 100%;">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            ref="grid"
                            v-bind ="initGrid"
                            @queryCellInfo="onQueryCellInfo"
                            @headerCellInfo="onHeaderCellInfo"
                            @actionComplete="gridComplete"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import StockCommon from "@/api/v2/StockManage/StockCommon";

export default {
  name: "suppliesStockOilPopup",
  mixins: [confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 콤보 갖고오기....
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      gridField: {
        title: "저장품 목록",
        count : 0,
        dataSource: [],
      },
      searchFilterOptions: {
        useFlag: 1,
        isOil: 1,
        wrhousList:"",  //다중선택된 창고코드 조회값
      },
      //드롭다운 코드
      commonCodeOptions: {
        taxDiv: commonCodesGetCommonCode("NOTAX_CODE",false,true,"",""),//NOTAX_CODE,TAX_DIV
        wrhousData: [],
        fields: { text: "comName", value: "comCode" },
      },
    };
  },

  async mounted() {
    //창고콤보(모든 저장품창고만)
    this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'N',suppliesFlag:'Y'});
     //유류 재고 리스트 조회
    await this.apiSearchList();
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    //그리드 세팅
    initGrid(){
      return{
        columns:  [
          {field: 'pathDesc',       textAlign: 'left',      width: 150,  visible: true,   allowEditing : false,  type: 'string',   headerText: '분류'  },
          {field: 'suppliesName',   textAlign: 'center',    width: 150,  visible: true,   allowEditing : false,  type: 'string',   headerText: '저장품명'  },
          {field: 'suppliesCode',   textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '저장품코드' },
          {field: 'standard',       textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'string',   headerText: '규격'  },
          {field: 'unit',           textAlign: 'left',      width: 50,   visible: true,   allowEditing : false,  type: 'string',   headerText: '단위' },
          {field: 'price',          textAlign: 'right',     width: 80,   visible: true,   allowEditing : false,  type: 'number',   headerText: '최근입고가', isNumericType: true, },
          {field: 'wrhousCode',     textAlign: 'center',    width: 80,   visible: false,  allowEditing : false,  type: 'string',   headerText: '창고코드'  },
          {field: 'wrhousName',     textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '창고'  },
          {field: 'stockQty',       textAlign: 'right',     width: 60,   visible: true,   allowEditing : false,  type: 'number',   headerText: '현재고', isNumericType: true,  },
          {field: 'taxDiv',         textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'string',   headerText: '면/과세', isCommonCodeField: true, dataSource: this.commonCodeOptions.taxDiv,},
          //{field: 'useFlag',        textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'boolean',  headerText: '사용여부',
          //  displayAsCheckBox: true, editType: "booleanedit" },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: false,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        isInPopup:true,
        pageSettings: { pageSize: 12 },
      };
    },
  },

  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async apiSearchList(){
      // 조회조건에 있는 창고만(권한창고)
      this.searchFilterOptions.wrhousList = this.commonCodeOptions.wrhousData.map(data => data.comCode).join('|')  ;
      this.gridField.dataSource = await StockCommon.getSuppliesStockList(this.searchFilterOptions);
    },
    //팝업 닫기
    onClickClose(){
      this.$emit('popupClosed', null);
    },
    //endregion

    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  },
};
</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>

</style>
