<template>
  <div>
    <ejs-dialog
        ref="eqpSearchPopupDialog"
        header="유류 장비 검색"
        width="1180"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">장비명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text ref="eqpName" id="eqpName" v-model="searchFilterOptions.eqpName" @keydown.enter="onClickSearch()"/>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">장비구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                            ref="searchEqpDiv"
                            v-model="searchFilterOptions.eqpDiv"
                            :dataSource="commonCodeOptions.eqpDiv"
                            :allowFiltering="false"
                            :fields="commonCodeOptions.fields"
                            cssClass="lookup-condition-dropdown"
                        >
                        </ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button button-div="GET" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <!-- 활성 : active -->
              <article class="body-article" style="height:500px">
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                          ref="grid"
                          v-bind ="initGrid"
                          :isInPopup="true"
                          @onGridDialogDoubleClickedOrEnterKeyed="onConfirmClicked"
                          @actionComplete="gridComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onConfirmClicked">확인</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onPopupClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
</style>

<script>
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import {getFormattedDate} from "@/utils/date";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default {
  name: "eqpOilSearchPopup",
  mixins: [confirmDialogMixin],
  components: {
    InputText,
    ejsGridWrapper,
    ErpButton,
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      gridField: {
        title: "유류 장비 목록",
        count : 0,
        dataSource: [],
      },
      searchFilterOptions: {
        eqpName: "",
        eqpDiv:"",
        fuelFlag: "Y"
      },
      //드롭다운 코드
      commonCodeOptions: {
        eqpDiv: commonCodesGetCommonCode("EQP_DIV",false,true,"",""),
        eqpStatus: commonCodesGetCommonCode("EQP_STATUS",false,true,"",""),
        fuelCode:[],
        fields: { text: "comName", value: "comCode" },
      },
    };
  },

  async mounted() {
    this.commonCodeOptions.fuelCode = await StockCommon.getOilSupplies({useFlag:null});
    this.initData();
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    initGrid() {
      return {
        columns: [
          { field: "eqpCode",     textAlign: 'center',  allowEditing: false, width: 70,   visible: true,  type: "string",  headerText: "장비코드", isPrimaryKey: true, },
          { field: "eqpName",     textAlign: 'left',    allowEditing: false, width: 150,  visible: true,  type: "string",  headerText: "장비명", },
          { field: "eqpDiv",      textAlign: 'center',  allowEditing: false, width: 80,   visible: true,  type: "string",  headerText: "장비구분", isCommonCodeField: true, groupCode: "EQP_DIV"},
          { field: "status",      textAlign: 'center',  allowEditing: false, width: 60,   visible: true,  type: "string",  headerText: "상태",     isCommonCodeField: true, groupCode: "EQP_STATUS"},
          { field: "useOl",       textAlign: 'center',  allowEditing: false, width: 60,   visible: false, type: "string",  headerText: "연료타입",  isCommonCodeField: true, groupCode: "EQP_USE_FUEL"},
          { field: "fuelCode",    textAlign: 'center',  allowEditing: false, width: 60,   visible: true,  type: "string",  headerText: "사용유류",  isCommonCodeField: true, dataSource: this.commonCodeOptions.fuelCode},
          {field: "purchsDate",   textAlign: 'center',  allowEditing: false, width: 80,   visible: true,  type: "string",  headerText: "구입일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          {field: "dsuseDate",    textAlign: 'center',  allowEditing: false, width: 80,   visible: false, type: "string",  headerText: "폐기일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "makr",        textAlign: 'left',    allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "제조사", },
          { field: "standard",    textAlign: 'left',    allowEditing: false, width: 60,   visible: false, type: "string",  headerText: "규격", },
          { field: "remarks",     textAlign: 'left',    allowEditing: false, width: 180,  visible: true,  type: "string",  headerText: "비고", },
          { field: "useFlag",     textAlign: 'center',  allowEditing: false, width: 60,   visible: false, type: "boolean", headerText: "사용여부", displayAsCheckBox: true, editType: "booleanedit", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: false,
        selectionSettings: {type: "Multiple", mode: "Both", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
  },

  /** ##################################### 화면 이벤트 ######################################## */
  methods: {
    initData(){
      this.$refs["eqpName"].focus();
      this.onClickSearch();
    },
    //region******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      this.gridField.dataSource = await StockCommon.getEqpCodeListPop(this.searchFilterOptions);
    },
    //확인및 더블클릭
    onConfirmClicked() {
      const selData = this.$refs.grid.getSelectedRecords();
      if(selData?.length == 0){
        this.errorToast("장비를 선택해 주십시오");
      }else{
        this.$emit("popupConfirmed", selData);
      }
    },
    //팝업 닫기
    onPopupClose(){
      this.$emit('popupConfirmed', null);
    },
    //endregion
    //region******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['treeName',].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 스타일 적용
    onQueryCellInfo(args) {
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['treeName',].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  },
};
</script>
